export const URL_DASHBOARD = "/dashboard";
export const URL_USER = "/users";
export const URL_SCHOOL = "/school"; // only admin access this

export const URL_SCHOOL_TEACHER = "/school/teacher/"; // for direct access by admin
export const URL_TEACHER = "/teacher/"; //for sidebar

export const URL_SCHOOL_CLASS = "/school/class/"; //for direct access by admin
export const URL_CLASS = "/class/"; //for sidebar

export const URL_SCHOOL_STUDENT_RESULT = "/school/student/result/";
export const URL_SCHOOL_STUDENT_TEST_RESULT = "/school/student/test/result/";
export const URL_SCHOOL_STUDENT_PROGRESS_REPORT = "/school/student/report/";
export const URL_SCHOOL_STUDENT_GAME = "/school/student/test/result/game";
export const URL_SCHOOL_STUDENT = "/school/student/"; // for direct access by admin
export const URL_SCHOOL_TEACHERS_STUDENT = "/schools/students/"; //for sidebar

export const URL_SCHOOL_ASSIGN_CLAAS_TO_STUDENT = "/school/class/student/"; // Class assign to student

export const URL_TEACHER_CLASSES = "/school/teacher/"; // school and admin add from teacher module

export const URL_TEST_ASSIGN_TO_CLASS_ADMIN = "/school/class-with-year/"; // for direct access by admin
export const URL_TEST_ASSIGN_TO_CLASS = "/class-with-year/"; // for sidebar
export const URL_TEST_RESULT_STUDENTS = "/student/test-result/";
export const URL_TEST_RESULT_STUDENTS_ADMIN = "/user/test-result/";

export const URL_ADMIN = "/admins";
export const URL_LOGIN = "/login";
export const URL_ADMIN_RESET_PASSWORD = "/reset-password/:token";
export const URL_EDIT_PROFILE = "/edit-profile";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_STAFF_MEMBERS = "/staff-members";
export const URL_SETTINGS = "/settings";
export const URL_SETTINGS_APP = "/settings/app";
export const URL_PAGES = "/game-static-page";
export const QUESTION_BANK = "/question-bank";
export const QUESTION_BANK_SCHOOLS = "/question-bank-schools";
export const QUESTION_BANK_MENU = "/question-bank-menu";
export const URL_LEGAL = "/legal";

/*
 * SUBSCRIPTION
 */
export const URL_SUBSCRIPTION = "/subscription/";
export const URL_UPGRADE_SUBSCRIPTION = "/upgrade-subscription/";

export const URL_ALL_GAMES = "/games/";

export const URL_CONSTITUTION_QUESTIONS = "/constitution/questions";
export const URL_JUDICIAL_SPIN_QUESTIONS = "/judicial/spin-questions";
export const URL_JUDICIAL_SUPREME_COURT_QUESTIONS = "/judicial/supreme-questions";
export const URL_LEGISLATIVE_QUESTIONS = "/legislative/bill-no-bill";
export const URL_LEGISLATIVE_BILL_BECOMES_LAW = "/legislative/how-bill-becomes-law";
export const URL_EXECUTIVE_ESCAPE_ROOM = "/executive/escape-room-question";
export const URL_QUIZ_QUESTION = "/quiz/question";
export const URL_FINAL_GAME_QUESTION = "/final-game/question";
export const URL_QUIZ_GAME = "/quiz/game";
export const URL_QUIZ_CATEGORY = "/quiz/category";

/**
 * HISTORY URLS
 *
 */

export const URL_H_RAPID_FIRE_QUESTIONS = "/history/rapid-fire/questions";
export const URL_HISTORY_BILL_NO_BILL = "/history/bill-no-bill/questions";

/**
 * FORIEGN LANGUAGE URLS
 */

export const URL_F_RAPID_FIRE_QUESTIONS = "/foreign-language/rapid-fire/questions";
export const URL_FOREIGN_LANGUAGE_QUIZ = "/foreign-language/quiz";
export const URL_FOREIGN_LANGUAGE_QUIZ_CATEGORY = "/foreign-language/quiz/category";
export const URL_FOREIGN_LANGUAGE_QUIZ_CATEGORY_QUESTION = "/foreign-language/quiz/category/question";

/**
 * SCIENCE URLS
 */

export const URL_S_RAPID_FIRE_QUESTIONS = "/science/rapid-fire/questions";
export const URL_SCIENCE_PERIODIC_TABLE = "/science/periodic-table/questions";
export const URL_SCIENCE_LOOSE_LINK = "/science/loose-list/add-game";
export const URL_SCIENCE_LOOSE_LINK_QUESTION = "/science/loose-list/questions";

/**
 * ENGLISH URLS
 */
export const URL_ENGLISH_CROSSWORD = "/english/cross-word/questions";
export const URL_ENGLISH_CROSSWORD_DETAIL = "/english/cross-word/detail";
export const URL_ENGLISH_QUESTIONS = "/english/rapid-fire/questions";

/**
 * ECONOMICS URLS
 */
export const URL_ECONOMICS = {
	PURSUIT_QUESTIONS: "/economics/pursuit/questions",
	CHASER_QUESTIONS: "/economics/chaser/questions",
	FINAL_QUESTIONS: "/economics/final/questions",
	ECONOMICS_QUESTIONS: "/economics/rapid-fire/questions",
};

/**
 * MATHS URLS
 */
export const URL_MATHS = {
	CONNECT4_ADD_GAME: "/maths/connect4/add-game",
	CONNECT4_QUESTION: "/maths/connect4/questions",
	MATHAHTZEE_ADD_GAME: "/maths/mathahtzee/add-game",
	MATHAHTZEE_QUESTION: "/maths/mathahtzee/questions",
};

//API URL
export const API_URL = {
	//Department
	ADD_DEPARTMENT: "department/add",

	CMS_LOGIN: "/user/cms/cmsLogin",
	FORGOT_PASSWORD: "/user/forgotPassword",

	ADMIN_CMS_CHANGE_PASSWORD: "/user/cms/cmsChangePassword",
	LOG_OUT: "/user/logout",

	//admin
	ADMIN_FORGOT_PASSWORD: "/user/cms/forgotPassword", //Ok cms(admin)
	ADMIN_RESET_PASSWORD: "/user/cms/resetPassword", //Ok

	//admin
	VIEW_ADMIN_USER_PROFILE: "/user/cms/viewAdminUserProfile", //cms
	EDIT_ADMIN_USER_PROFILE: "/user/cms/editAdminUser", //cms

	GET_USER_STATUS: "/user/cms/status", //GET//cms(admin and school and teacher)

	//constitution
	ADD_QUESTION: "/constitution/question", //post//Done
	EDIT_QUESTION: "/constitution/question", //put//Done
	VIEW_QUESTION: "/constitution/questionByID", //post//Done
	VIEW_ALL_QUESTION: "/constitution/allQuestion", //Get//Done
	VIEW_ALL_BANK_QUESTIONS: "/questionbank/allQuestionFromBank", //Get//Done
	COPY_BANK_QUESTION: "/questionbank/question",
	//user
	ADD_USER: "/cms/user", //POST
	VIEW_USER_PROFILE_BY_ID_CMS: "/cms/get-user", //POST
	EDIT_USER_PROFILE_BY_ID: "/cms/user", //PUT
	FETCH_ALL_USER_LIST: "/cms/users", //GET

	//School(CMS)
	ADD_SCHOOL: "/cms/school/admin", //POST//cms(admin)
	EDIT_SCHOOL: "/cms/school", //PUT//cms(admin and school)
	VIEW_SCHOOL: "/cms/school", //GET//cms(admin and school)
	LIST_SCHOOL: "/cms/school/list", //GET//cms(admin)

	//class
	ADD_CLASS: "/cms/school/class", //POST//cms(admin and school)
	EDIT_CLASS: "/cms/school/class", //PUT//cms(admin and school)
	VIEW_CLASS: "/cms/school/class", //GET//cms(admin and school)
	LIST_CLASS: "/cms/school/class/list", //GET//cms(admin and school)
	//Class Years
	GET_CLASS_AND_YEARS: "/cms/class/years", //GET//cms//(admin and school)

	//student(CMS)
	ADD_STUDENT_IN_SCHOOL: "/cms/school/student", //POST//cms(admin and school and teacher)
	VIEW_STUDENT_IN_SCHOOL: "/cms/school/student", //GET//cms(admin and school and teacher)
	EDIT_STUDENT_IN_SCHOOL: "/cms/school/student", //PUT//cms(admin and school and teacher)
	LIST_STUDENTS_BY_SCHOOL: "/cms/school/students", //GET//cms(admin and school and teacher)
	LIST_STUDENTS_BY_SCHOOL_WITHOUT_PAGINATION: "/cms/school/allStudents", //GET//cms(admin and school and teacher)
	ASSIGN_CLASS_TO_STUDENT: "/cms/school/student/assign-class", //PUT//cms(admin and school and teacher)
	GET_STUDENT_ASSIGN_CLASSES: "/cms/school/student/assign-class", //GET//cms(admin and school and teacher)
	ACTIVE_STATUS_CHANGE_CLASS_STUDENT: "/cms/school/student/status", //PUT//cms(admin and school and teacher)
	LIST_STUDENTS_NOT_ASSIGNED_CLASS: "/cms/school/allStudent",
	GET_STUDENT_RESULT_SUBJECT_WISE: "/cms/school/students/view-student-result-subject-wise",
	EDIT_STUDENT_ASSIGNED_SUBJECTS: "/cms/school/class/student/subject",
	//Teacher(CMS)
	ADD_TEACHER: "/cms/teacher", //POST//cms(admin and school)
	EDIT_TEACHER: "/cms/teacher", //PUT//cms(admin and school and teacher)
	VIEW_TEACHER: "/cms/teacher", //GET//cms(admin and school and teacher)
	LIST_TEACHER: "/cms/teacher/list", //GET//cms(admin and school)
	DELETE_ASSIGNED_SUBJECTS_TO_TEACHER: "/cms/teacher/assign-class", //DELETE
	ASSIGN_CLASS_TO_TEACHER: "/cms/teacher/assign-class", //PUT//cms(admin and school)
	GET_TEACHER_ASSIGN_CLASSES: "/cms/teacher/assign-class", //GET//cms(admin and school)
	GET_TEACHER_CLASSES_BY_SUBJECT: "/cms/teacher/subject/assign-class",
	LIST_SELECTED_SUBJECTS: "/cms/teacher/get-remaining-subjects-assigned-to-teacher",
	GET_TEACHERS_ASSIGNED_TO_CLASS: "/cms/teacher/get-teachers-assigned-to-class", //POST//cms(admin and school)
	GET_SUBJECTS_ASSIGNED_TO_CLASS: "/cms/teacher/get-class-wise-subjects-assigned-to-teacher", //POST//cms(admin and school)
	ACTIVE_STATUS_CHANGE_TEACHER_AND_STUDENT: "/cms/active-inactive/teacher/student", //PATCH
	MULTIPLE_STATUS_CHANGE_TEACHER_AND_STUDENT: "/cms/active-inactive/multiple/teachers/students",
	//School Tests(CMS)
	GET_SUBJECTS_ASSIGNED_TO_TEACHER: "/cms/get-subjects-assigned-to-teacher", //GET//cms(admin and school and teacher)
	GET_CLASS_ASSIGNED_TO_TEACHER: "/cms/get-classes-assigned-to-teacher", //GET//cms(admin and school and teacher)
	GET_SCHOOL_TEACHERS: "/cms/teacher/get-school-teachers", //GET//cms(admin and school and teacher)
	ADD_SCHOOL_TEST: "/cms/school/class/test", //POST
	EDIT_SCHOOL_TEST: "/cms/school/class/test", //PUT//cms(admin and school and teacher)
	GET_SCHOOL_TEST: "/cms/school/class/test", //GET//cms(admin and school and teacher)
	GET_ALL_SCHOOL_TESTS: "/cms/school/class/tests", //GET//cms(admin and school and teacher)
	CLOSE_SCHOOL_TEST: "/cms/school/class/test/close", //PUT//cms(admin and school and teacher)
	GET_STUDENT_RESULT_LIST_BY_TEST_WISE: "/cms/school/class/test/result/students", //GET//cms(admin and school and teacher)
	GET_STUDENT_RESULT_BY_TEST_AND_STUDENTID: "/cms/school/class/student/test/result",
	GET_USER_RESULT: "/cms/user/result", //GET//cms(admin)
	TOGGLE_SCHOOL_TEST: "/cms/school/class/test/module",

	//student test result
	GET_STUDENT_TESTS: "/cms/school/class/student/tests",
	GET_STUDENT_GAME_QUESTIONS: "/cms/school/class/student/tests/question",

	// progress report
	GET_ATTENDED_TEST_BY_STUDENTS: "/student/get-test-list-of-student",
	GET_PROGRESS_REPORT_DETAILS: "/student/get-progress-report",

	// game static page
	FETCH_ALL_GAME_STATIC_PAGE: "/staticPage/games", //admin//get
	FETCH_GAME_STATIC_PAGE_BY_MODULE_NAME: "/staticPage/game", //admin+web//get
	EDIT_GAME_STATIC_PAGE_BY_MODULE_NAME: "/staticPage/games", //admin//put
	FETCH_GAME_STATIC_PAGE_BY_MODULE_NAME_WEB: "/staticPage/game_by_module_name", //GET//cms(admin and school and teacher)

	//Subscription
	FETCH_SUBSCRIPTION_DETAILS: "/cms/school/subscription-details", //GET,
	FETCH_SUBSCRIPTION_UPGRADE_PLANS: "/stripe/subscription", //GET
	UPGRADE_SUBSCRIPTION_PLAN: "/stripe/customer-portal-link", //GET
	ADD_ON_SUBSCRIPTION: "/stripe/check-out/form/add-on", //POST
	GET_REMAINING_SUBJECTS: "/school/remaining-subjects", //GET
	ADD_ON_SUBSCRIPTION_BY_ADMIN: "/cms/school/add-on", //POST
	GET_SUBJECTS_FOR_UPGRADE_SUBSCRIPTION: "/school/subjects/upgrade/subscription", //GET,POST

	//Judicial supreme court question
	ADD_JUDICIAL_SUPREME_QUESTION: "/judicial/supreme-question", //cms//post
	EDIT_JUDICIAL_SUPREME_QUESTION: "/judicial/supreme-question", //cms//put
	JUDICIAL_SUPREME_QUESTIONS: "/judicial/supreme-question", //cms//get

	//Judicial Spin Question
	ADD_JUDICIAL_SPIN_QUESTION: "/judicial/spin-question", //cms//post
	EDIT_JUDICIAL_SPIN_QUESTION: "/judicial/spin-question", //cms//put
	JUDICIAL_SPIN_QUESTIONS: "/judicial/spin-question", //cms//get

	//Legislative(BillNoBill)
	ADD_BILL_NO_BILL_QUESTION: "/legislative/bil-no-bill/question", //cms//post
	EDIT_BILL_NO_BILL_QUESTION: "/legislative/bil-no-bill/question", //cms//put
	FETCH_BILL_NO_BILL_QUESTION: "/legislative/bil-no-bill/question", //cms//get

	// IMAGE or VIDEO
	UPLOAD_MEDIA: "/question/media",

	//Legislative(BillBecomesLaw)
	ADD_BILL_BECOMES_LAW: "/legislative/bill-law", //cms//POST
	EDIT_BILL_BECOMES_LAW: "/legislative/bill-law", //cms//PUT
	FETCH_BILL_BECOMES_LAW_DATA: "/legislative/bill-law", //cms/GET

	//Executive(Escape Room)
	ADD_ESCAPE_ROOM_QUESTION: "/executive/escape-room-question", //cms//Post
	EDIT_ESCAPE_ROOM_QUESTION: "/executive/escape-room-question", //cms//Put
	FETCH_ESCAPE_ROOM_QUESTIONS: "/executive/escape-room-question", //cms//Get

	//Quiz
	ADD_QUIZ_QUESTION_MODULE_WISE: "/quiz/question", //cms//POST(admin school and teacher)
	EDIT_QUIZ_QUESTION_MODULE_WISE: "/quiz/question", //cms//PUT(admin school and teacher)
	FETCH_QUIZ_QUESTION_MODULE_WISE: "/quiz/question", //cms//GET(admin school and teacher)
	FETCH_QUIZ_POINTS: "/quiz/points", //cms//GET(admin school and teacher)
	FETCH_QUIZ_TITLE: "/quiz/test-wise-quiz-title", //CMS // GET
	ADD_OR_EDIT_QUIZ_TITLE: "/quiz/quiz-title", //CMS//POST FOR ADD, PUT FOR EDIT, PATCH FOR STATUS CHANGE
	FETCH_QUIZ_CATEGORY: "/quiz/title-wise-quiz-module", //CMS //GET
	FETCH_ALL_CATEGORIES: "/questionbank/modules-list/quiz/school", //CMS //GET

	//Final Game
	ADD_FINAL_GAME_QUESTION: "/final/question", //cms//POST(admin school and teacher)
	EDIT_FINAL_GAME_QUESTION: "/final/question", //cms//PUT(admin school and teacher)
	FETCH_FINAL_GAME_QUESTION: "/final/question", //cms//GET(admin school and teacher)
	FETCH_FINAL_GAME_POINTS_WITH_STATUS: "/final/points", //cms//GET(admin school and teacher)

	//Legal Text
	LEGAL_URL: "/site-info/pages",
	UPDATE_LEGAL_DESCRIPTION: "/site-info/page",

	//subjects
	LIST_SUBJECTS: "/subject", //GET

	//Sub Subject
	LIST_SUB_SUBJECT: "/fetch-sub-subjects",

	//fetching subject of teacher which he has access of
	FETCH_SUBJECT: "/cms/teacher/assign-subject",

	/**
	 * QUESTION APIS -->
	 */
	/**
	 * history and Foreign Language rapid fire
	 */
	FETCH_RAPID_FIRE_QUESTIONS: "/rapid-fire/question",
	ADD_RAPID_FIRE_QUESTION: "/rapid-fire/question",
	EDIT_RAPID_FIRE_QUESTION: "/rapid-fire/question",
	FETCH_RAPID_FIRE_BY_QUESTION_ID: "/rapid-fire/question/",

	//FOREIGN LANG API
	GET_FOREIGN_LANGUAGE_DATA: "/quiz/test-wise-quiz-title",
	FOREIGN_LANGUAGE_QUIZ_QUESTION_API: "/quiz/quiz-title",
	GET_FOREIGN_LANGUAGE_QUIZ_CATEGORY_DATA: "/quiz/title-wise-quiz-module",
	FOREIGN_LANGUAGE_QUIZ_CATEGORY_API: "/quiz/quiz-category",
	QUESTION_BANK_FOREIGN_LANGUAGE_QUIZ: "/questionbank/title-list/quiz/school",
	GET_FOREIGN_LANGUAGE_CATEGORY_DROPDOWN: "/questionbank/modules-list/quiz/school",

	/*
	 * Science periodic-table question
	 */
	ADD_PERIODIC_TABLE_QUESTION: "/science/periodic-table-question", //cms//POST
	EDIT_PERIODIC_TABLE_QUESTION: "/science/periodic-table-question", //CMS//PUT
	FETCH_PERIODIC_TABLE_QUESTION: "/science/periodic-table-question", //CMS//GET

	ADD_LOOSE_LINK_QUESTION: "/loose-link/question", //CMS// POST
	EDIT_LOOSE_LINK_QUESTION: "/loose-link/question", // CMS //PUT
	LIST_LOOSE_LINK_QUESTION: "/loose-link/question", //CMS //GET

	ADD_LOOSE_LINK_TITLE: "/loose-link/loose-link-title", //CMS// POST
	EDIT_LOOSE_LINK_TITLE: "/loose-link/loose-link-title", // CMS //PUT
	LIST_LOOSE_LINK_TITLE: "/loose-link/loose-link-title", //CMS //GET
	STATUS_CHANGE_LOOSE_LINK: "/loose-link/loose-link-title", //CMS // PATCH

	/*
	 * Economics Routes
	 */
	ADD_PURSUIT_RAPID_FIRE_QUESTION: "/pursuit-rapid-fire/question", //cms//POST
	EDIT_PURSUIT_RAPID_FIRE_QUESTION: "/pursuit-rapid-fire/question", //CMS//PUT
	FETCH_PURSUIT_RAPID_FIRE_QUESTION: "/pursuit-rapid-fire/question", //CMS//GET

	ADD_CHASER_QUESTION: "/chaser/question", //CMS//POST
	EDIT_CHASER_QUESTION: "/chaser/question", //CMS//PUT
	FETCH_CHASER_QUESTION: "/chaser/question", //CMS//GET

	ADD_FINAL_ROUND_QUESTION: "/pursuit/final/question", //CMS//POST
	EDIT_FINAL_ROUND_QUESTION: "/pursuit/final/question", //CMS//PUT
	FETCH_FINAL_ROUND_QUESTION: "/pursuit/final/question", //CMS//GET
	SET_QUESTION_LIMIT: "/pursuit/final/set-pursuit-final-question-limit", //CMS//PUT

	/*
	 * English Question
	 */

	ADD_CROSSWORD_TITLE: "/crossword/crossword-title", //CMS// POST
	EDIT_CROSSWORD_TITLE: "/crossword/crossword-title", // CMS //PUT
	LIST_CROSSWORD_TITLE: "/crossword/crossword-title", //CMS //GET
	STATUS_CHANGE_CROSSWORD: "/crossword/active-inactive-crossword-title", //CMS // PUT

	ADD_CROSSWORD_PUZZLE: "/crossword/crossword-puzzle", //CMS //POST
	EDIT_CROSSWORD_PUZZLE: "/crossword/crossword-puzzle", //CMS //PUT
	VIEW_CROSSWORD_PUZZLE: "/crossword/crossword-puzzle", //CMS //GET

	/*
	 * Maths Question
	 */

	ADD_CONNECT4_QUESTION: "/connect4/question", //CMS// POST
	EDIT_CONNECT4_QUESTION: "/connect4/question", // CMS //PUT
	LIST_CONNECT4_QUESTION: "/connect4/question", //CMS //GET

	ADD_CONNECT4_TITLE: "/connect4/connect4-title", //CMS// POST
	EDIT_CONNECT4_TITLE: "/connect4/connect4-title", // CMS //PUT
	LIST_CONNECT4_TITLE: "/connect4/connect4-title", //CMS //GET
	STATUS_CHANGE_CONNECT4: "/connect4/connect4-title", //CMS // PATCH

	ADD_MATHAHTZEE_QUESTION: "/mathahtzee/question", //CMS// POST
	EDIT_MATHAHTZEE_QUESTION: "/mathahtzee/question", // CMS //PUT
	LIST_MATHAHTZEE_QUESTION: "/mathahtzee/question", //CMS //GET

	ADD_MATHAHTZEE_TITLE: "/mathahtzee/mathahtzee-title", //CMS// POST
	EDIT_MATHAHTZEE_TITLE: "/mathahtzee/mathahtzee-title", // CMS //PUT
	LIST_MATHAHTZEE_TITLE: "/mathahtzee/mathahtzee-title", //CMS //GET
	STATUS_CHANGE_MATHAHTZEE: "/mathahtzee/mathahtzee-title", //CMS // PATCH

	//DASHBOARD
	GET_DASHBOARD_DATA: "/get-dashboard-data", //CMS // GET
	GET_TEACHERS_CLASS: "/get-assigned-classes-to-teacher", //CMS // GET
	GET_SCHOOL_GRAPH_DATA: "/dashboard/graph/no-of-school", //CMS //GET
	GET_NO_OF_USER: "/dashboard/graph/no-of-user", //CMS //GET
	GET_NO_OF_SUBSCRIPTION: "/dashboard/graph/no-of-subscription", //CMS //GET
	GET_ACTIVE_COUNT_OF_TEACHER_STUDENTS: "/dashboard/graph/active-count/teacher-student-test", // CMS //GET
	GET_SUBJECT_GRAPH_FOR_SCHOOL: "/dashboard/graph/subject-wise-test", //CMS //POST
	GET_SUBSCRIPTION_DETAILS: "/dashboard/subscription-details/school", //CMS //GET
	GET_TOP_STUDENTS: "/dashboard/top-5/students-detail", //CMS //GET
	GET_FIRST_ATTEMPT_PERCENTAGE: "/dashboard/graph/subject-wise-first-attempt-percentage", //CMS //GET

	//SAMPLE QUESTIONS
	ADD_SAMPLE_QUESTIONS: "/questionbank/sample-questions", //CMS //POST
	GET_SAMPLE_QUESTIONS: "/questionbank/sample-questions", //CMS //GET

	//CHATGPT QUESTION
	GET_CHATGPT_QUESTION: "/chat-gpt/get-question-from-text",
	GET_CHATGPT_QUESTION_DOCUMENT: "/chat-gpt/get-question-from-document",
	SAVE_CHATGPT_SAMPLE_QUESTION: "/chat-gpt/questionbank/sample-questions",
	SAVE_CHATGPT_CONSTITUTION_QUESTION: "/chat-gpt/constitution/question",
	SAVE_CHATGPT_JUDICIAL_SPIN_QUESTION: "/chat-gpt/judicial/spin-question",
	SAVE_CHATGPT_JUDICIAL_SUPREME_QUESTION: "/chat-gpt/judicial/supreme-question",
	SAVE_CHATGPT_LEGISLATIVE_BILL_NO_BILL_QUESTION: "/chat-gpt/legislative/bil-no-bill/question",
	SAVE_CHATGPT_EXECUTIVE_ESCAPE_ROOM_QUESTION: "/chat-gpt/executive/escape-room-question",
	SAVE_CHATGPT_RAPID_FIRE_QUESTION: "/chat-gpt/rapid-fire/question",
	SAVE_CHATGPT_HISTORY_BILL_NO_BILL_QUESTION: "/chat-gpt/legislative/bil-no-bill/question",
	SAVE_CHATGPT_QUIZ_QUESTION: "/chat-gpt/quiz/question",
	SAVE_CHATGPT_FINAL_QUESTION: "/chat-gpt/final/question",
	SAVE_CHATGPT_PURSUIT_QUESTION: "/chat-gpt/pursuit-rapid-fire/question",
	SAVE_CHATGPT_PURSUIT_CHASER_QUESTION: "/chat-gpt/chaser/question",
	SAVE_CHATGPT_PERIODIC_TABLE_QUESTION: "/chat-gpt/science/periodic-table-question",
	SAVE_CHATGPT_LOOSE_LINK_QUESTION: "/chat-gpt/loose-link/question",
	SAVE_CHATGPT_CONNECT4_QUESTION: "/chat-gpt/connect4/question",
	SAVE_CHATGPT_MATHAHTZEE_QUESTION: "/chat-gpt/mathahtzee/question",
};
