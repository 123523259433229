import React from "react";
import { Switch, Route } from "react-router-dom";

// COMPONENTS
import Login from "Components/Pages/Login/Login";
import ResetPassword from "../Components/Pages/ResetPassword/ResetPassword";

// ROUTING COMPONENTS
import ProtectedRoute from "./ProtectedRoute";
import RoutesList from "./RoutesList";
import { URL_ADMIN_RESET_PASSWORD, URL_LOGIN } from "Helpers/Paths";

function Routes() {
	return (
		<Switch>
			<Route path={URL_LOGIN} component={Login} />
			<Route path={URL_ADMIN_RESET_PASSWORD} component={ResetPassword} />
			<ProtectedRoute>
				{RoutesList.map((route, index) => (
					<React.Fragment key={index}>
						{route.sidebar && route.subnest ? (
							route.sidebar.nested.map((child, index) => {
								return child.single ? (
									<Route key={index} path={child.path} exact={child.exact} component={child.component} />
								) : (
									child.sub_nested.map((item, index) => <Route key={index} path={item.path} exact={item.exact} component={item.component} />)
								);
							})
						) : route.simple ? (
							route.sidebar.nested.map((child, index) => <Route key={index} path={child.path} component={child.component} />)
						) : (
							<Route path={route.path} exact={route.exact} component={route.component} />
						)}
					</React.Fragment>
				))}
			</ProtectedRoute>
		</Switch>
	);
}

export default Routes;
