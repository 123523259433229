import { css } from "styled-components";

// COLOR PALLET
export const COLORS = {
	// BLUE
	PRIMARY: "#ff7e19",
	PRIMARY_DARK: "#e66300",
	PRIMARY_LIGHT: "#ffaf4e",

	// ORANGE
	SECONDARY: "#2196f3",
	SECONDARY_DARK: "#1769aa",
	SECONDARY_LIGHT: "#4dabf5",

	ORANGE_DARK: "#f78035",
	ORANGE_LIGHT: "#FAA637",

	//CYAN
	TERTIARY: "#3A82A7",
	TERTIARY_LIGHT: "#92abbd",

	//RED
	DANGER: "#d32f2f",

	//GREEN
	GREEN: "#008000",

	//BLACK
	BLACK: "#000",
	TRANSPARENT_BLACK: "#00000040",

	GREY_TEXT_COLOR: "#707070",
	GREY_TEXT_LIGHT: "#788195",
	GREY_BORDER: "#96969673",
};

// BREAKPOINTS
export const BREAKPOINTS_VALUE = {
	DISPLAY: 1750,
	DISPLAY_1650: 1650,
	DISPLAY_1600: 1600,
	DISPLAY_1550: 1550,
	DESKTOP: 1450, // x_large
	LAPTOP: 1278, // large
	LAPTOP_1200: 1200,
	LAPTOP_1100: 1100,
	LAPTOP_1050: 1050,
	TABLET: 960, // medium
	TABLET_900: 900,
	TABLET_860: 860,
	TABLET_800: 800,
	TABLET_725: 725,
	TABLET_700: 700,
	PHABLET: 600, // small
	MOBILE: 450, // x_small
};
export const BREAKPOINTS = {
	A_DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP}px`,
	A_LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP}px`,
	A_TABLET: `${BREAKPOINTS_VALUE.TABLET}px`,
	A_PHABLET: `${BREAKPOINTS_VALUE.PHABLET}px`,
	A_MOBILE: `${BREAKPOINTS_VALUE.MOBILE}px`,

	DISPLAY: `${BREAKPOINTS_VALUE.DISPLAY - 1}px`,
	DISPLAY_1550: `${BREAKPOINTS_VALUE.DISPLAY_1550 - 1}px`,
	DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP - 1}px`, // x_large
	LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP - 1}px`, // large
	TABLET: `${BREAKPOINTS_VALUE.TABLET - 1}px`, // medium
	PHABLET: `${BREAKPOINTS_VALUE.PHABLET - 1}px`, // small
	MOBILE: `${BREAKPOINTS_VALUE.MOBILE - 1}px`, // x_small
};

//Fonts

// export const FONTS = {
// PRIMARY         : "Gotham Book",
// PRIMARY_BOLD    : "Gotham Bold",
// PRIMARY_LIGHT   : "Gotham Thin",
// PRIMARY_MEDIUM  : "Gotham Medium",
// PRIMARY_ITALIC  : "Gotham Book Italic",
// SECONDARY         : "PolarisCondensed-Book",
// SECONDARY_MEDIUM  : "PolarisCondensed-Medium",
// SECONDARY_HEAVY   : "PolarisCondensed-Heavy"
// };

export const FONTS = {
	SECONDARY: "Rubik",
	SECONDARY_ITALIC: "Rubik-Italic",
	SECONDARY_BLACK: "Rubik-Black",
	SECONDARY_BOLD: "Rubik-Bold",
	SECONDARY_SEMI_BOLD: "Rubik-Medium",
	SECONDARY_LIGHT: "Rubik-Light",

	QUATERNARY: "Noteworthy-Lt",

	QUINARY: "Chalkduster",
};

export const DIMENSIONS = {
	HEADER: 64,
	SIDEBAR: {
		FULL: 240,
		MINI: 50,
	},
};

export const responsive = Object.keys(BREAKPOINTS).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (max-width: ${BREAKPOINTS[label]}) {
			${css(...args)}
		}
	`;
	return acc;
}, {});
