export const ACTION_TYPES = {
	TOAST_MESSAGE_ENABLE: "TOAST_MESSAGE_ENABLE",
	TOAST_MESSAGE_DISABLE: "TOAST_MESSAGE_DISABLE",
	SERVER_ERROR_ENABLE: "SERVER_ERROR_ENABLE",
	//for school/view tests
	TEST_YEAR_ID: "TEST_YEAR_ID",
	TEST_CLASS_ID: "TEST_CLASS_ID",
	TEST_SUBJECT_ID: "TEST_SUBJECT_ID",
	TEST_TEACHER_ID: "TEST_TEACHER_ID",
	//for school/student/view tests
	STUDENT_TEST_YEAR_ID: "STUDENT_TEST_YEAR_ID",
	STUDENT_TEST_CLASS_ID: "STUDENT_TEST_CLASS_ID",
	STUDENT_TEST_SUBJECT_ID: "STUDENT_TEST_SUBJECT_ID",
	STUDENT_TEST_TEACHER_ID: "STUDENT_TEST_TEACHER_ID",

	CLEAR_TEST_STATES: "CLEAR_TEST_STATES",
	SELECTED_SUBJECTS_FOR_UPGRADE_SUBSCRIPTION: "SELECTED_SUBJECTS_FOR_UPGRADE_SUBSCRIPTION",
};

export const showToast = (message) => {
	return {
		type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
		message: message || "",
	};
};
export const hideToast = () => {
	return {
		type: ACTION_TYPES.TOAST_MESSAGE_DISABLE,
	};
};

// / SERVER ERROR /
export const showServerError = () => {
	return {
		type: ACTION_TYPES.SERVER_ERROR_ENABLE,
	};
};

//for school/view tests
export const updateYearId = (id) => {
	return {
		type: ACTION_TYPES.TEST_YEAR_ID,
		testYearId: id,
	};
};

export const updateClassId = (id) => {
	return {
		type: ACTION_TYPES.TEST_CLASS_ID,
		testClassId: id,
	};
};

export const updateSubjectId = (id) => {
	return {
		type: ACTION_TYPES.TEST_SUBJECT_ID,
		testSubjectId: id,
	};
};

export const updateTeacherId = (id) => {
	return {
		type: ACTION_TYPES.TEST_TEACHER_ID,
		testTeacherId: id,
	};
};

//for school/student/view tests
export const updateStudentTestYearId = (id) => {
	return {
		type: ACTION_TYPES.STUDENT_TEST_YEAR_ID,
		studentTestYearId: id,
	};
};

export const updateStudentTestClassId = (id) => {
	return {
		type: ACTION_TYPES.STUDENT_TEST_CLASS_ID,
		studentTestClassId: id,
	};
};

export const updateStudentTestSubjectId = (id) => {
	return {
		type: ACTION_TYPES.STUDENT_TEST_SUBJECT_ID,
		studentTestSubjectId: id,
	};
};

export const updateStudentTestTeacherId = (id) => {
	return {
		type: ACTION_TYPES.STUDENT_TEST_TEACHER_ID,
		studentTestTeacherId: id,
	};
};

export const clearTestStates = () => {
	return {
		type: ACTION_TYPES.CLEAR_TEST_STATES,
	};
};

export const selectedSubjectsForUpgradeSubscription = (data) => {
	localStorage.setItem("subjectList", JSON.stringify(data));
	return {
		type: ACTION_TYPES.SELECTED_SUBJECTS_FOR_UPGRADE_SUBSCRIPTION,
		subjectList: data,
	};
};
