import styled from "styled-components";

// THEME
import { Layout } from "antd";

import { DIMENSIONS } from "Styles/Constants";

export const RightContentWrapper = styled.div`
	width: calc(100% - ${DIMENSIONS.SIDEBAR.FULL}px);
`;

export const ContentWrapper = styled.div`
	margin: ${DIMENSIONS.HEADER}px 0px 0;
	overflow: auto;
	padding: 15px;
	height: calc(100vh - ${DIMENSIONS.HEADER}px);
	.table-wrapper {
		background: #fff;
		box-shadow: 0 0 15px #dbdbdb;
		border: 1px solid #e9e9e9;
		padding: 35px;
		.title-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;
			.title {
				font-size: 19px;
				font-weight: 500;
				color: #788195;
				width: 100%;
				margin-right: 17px;
				display: flex;
				align-items: center;
				white-space: nowrap;
				::before {
					content: "";
					width: 5px;
					height: 40px;
					background-color: #e4e6e9;
					display: flex;
					margin: 0 15px 0 0;
				}
				::after {
					content: "";
					width: 100%;
					height: 1px;
					background-color: #e4e6e9;
					display: flex;
					margin: 0 0 0 15px;
				}
			}
		}
	}
`;

export const LayoutStyled = styled(Layout)`
	overflow-x: hidden;
`;
