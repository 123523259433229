import { notification } from "antd";
import Api from "./ApiHandler";
import { API_URL } from "./Paths";

export const isEqualsArray = (arr1, arr2) => {
	if (!(arr1 instanceof Array) || !(arr1 instanceof Array)) return false;

	if (arr1.length !== arr2.length) return false;

	if (arr1 instanceof Object && arr2 instanceof Object) {
		let isEqual = true,
			arrayLength = arr1.length;

		for (let index = 0; index < arrayLength && isEqual; index++) {
			if (JSON.stringify(arr1[index]) !== JSON.stringify(arr2[index])) isEqual = false;
		}

		return isEqual;
	}

	return false;
};

export const isEmpty = (obj) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}

	return true;
};

export const isIEBrowser = () => {
	const ua = window.navigator.userAgent;

	const msie = ua.indexOf("MSIE ");
	const msie11 = ua.indexOf("Trident/");

	return msie > 0 || msie11 > 0;
};

export const getBase64 = (img, callback) => {
	const reader = new FileReader();

	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export const beforeFileUpload = (file) => {
	const isLt2M = file.size / 1024 / 1024 < 2;
	const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

	if (!isJpgOrPng) notification.error({ message: "Error", description: "You can only upload JPG/PNG file!", duration: 3 });

	if (!isLt2M) notification.error({ message: "Error", description: "Image must smaller than 2MB!", duration: 3 });

	return isJpgOrPng && isLt2M;
};

export const uploadMedia = async (mediaType, imageState) => {
	try {
		let fileName = undefined;
		const formData = new FormData();

		formData.append("file", imageState.file);

		let fileResponse = await new Api().post(`${API_URL.UPLOAD_MEDIA}`, { params: { mediaType: mediaType.toUpperCase() }, data: formData });

		fileName = fileResponse.data.fileName;

		return fileName;
	} catch (error) {
		console.log("🚀 ~ file: Utils.js ~ line 78 ~ uploadMedia ~ error", error);
	}
};

export const importAll = (r) => r.keys().map(r);
