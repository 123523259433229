import styled from "styled-components";
import BackgroundImage from "Assets/Images/LoginPageLayer.png";
import { FONTS, responsive } from "Styles/Constants";
import { Input } from "antd";

export const ParentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	background-color: #fff;
	width: 100%;
	background-image: url(${BackgroundImage});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	justify-content: space-between;
	.main-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		.modal {
			padding: 35px 30px;
			border-radius: 10px;
			box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
			background: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
			width: 470px;

			.header {
				width: 100%;
				height: 2rem;
				display: flex;
				justify-content: flex-start;
				font-family: ${FONTS.SECONDARY_SEMI_BOLD};
				font-size: 30px;
				font-weight: 600;
				text-align: center;
				color: #010101;
				color: #010101;
				/* justify-content: center; */
				word-spacing: 3px;
				${responsive.DISPLAY_1550`
					font-size: 25px;
					
				`}
			}

			.text {
				display: flex;
				font-family: ${FONTS.SECONDARY_SEMI_BOLD};
				font-size: 16px;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				color: #010101;
			}

			.login-button {
				background-color: #010101;
				color: white;
				width: 500px;
				height: 65px;
				border-radius: 10px;
				background-color: #000;
				.font {
					width: 52px;
					height: 14px;
					font-family: ${FONTS.SECONDARY_SEMI_BOLD};
					font-size: 14px;
					font-weight: 600;
					text-align: center;
					color: #fff;
				}
			}

			.content {
				display: flex;
				flex-direction: column;
				gap: 48px;
				${responsive.DISPLAY_1550`
					gap : 38px
				`}
				.flex-column-parent {
					display: flex;
					flex-direction: column;
					gap: 15px;
					.flex-column {
						display: flex;
						flex-direction: column;
					}
				}

				.forgot-password-parent {
					display: flex;
					justify-content: flex-end;
					.forgot-password {
						width: fit-content;
						font-family: ${FONTS.SECONDARY_SEMI_BOLD};
						font-size: 14px;
						text-align: center;
						margin: 0px;
						padding: 0px;
						color: #0056e6;
						text-decoration: underline;
						cursor: pointer;
					}
				}

				.login-button-parent {
					.button {
						background-color: black;
						color: white;
						width: 100%;
						height: 55px;
						padding: 1rem;
						border-radius: 10px;
						border-color: #000;
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;

						:hover {
							box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.24);
						}
						.login-font {
							font-family: ${FONTS.SECONDARY_SEMI_BOLD};
							font-size: 18px;
							font-weight: 600;
							text-align: center;
							margin: 0px;
							padding: 0px;
						}
						&.cancel {
							width: 35% !important;
							height: 45px;
							background-color: transparent;
							color: black;
							border: 2px solid black;
						}
						&.submit {
							width: 35% !important;
							height: 45px;
						}
					}

					.loader {
						height: 20px;
						width: 20px;
						color: white;
					}
				}
			}
		}
	}
`;

// export const LoginWrapper = styled.div``;

// export const LoginBox = styled.div`
// 	background: rgba(0, 0, 0, 0.5);
// 	border-radius: 5px;
// 	box-shadow: 0px 0px 20px rgba(92, 111, 139, 0.12);
// 	padding: 30px;
// 	width: 500px;

// 	.login-form-container {
// 		margin: 20px auto 30px;
// 		width: 90%;

// 		.ant-input {
// 		}

// 		.ant-input,
// 		.ant-input-password {
// 			background-color: rgba(0, 0, 0, 0.2) !important;
// 			color: #fff;
// 			border: 0;
// 			padding: 10px 15px;
// 		}

// 		.ant-input-password {
// 			.ant-input {
// 				background: transparent;
// 				padding: 0;
// 			}
// 			.ant-input-suffix {
// 				svg {
// 					color: rgba(255, 255, 255, 0.5);
// 				}
// 			}
// 		}
// 	}
// 	.button-container {
// 		text-align: center;

// 		.btn {
// 			text-transform: uppercase;
// 			min-width: 125px;
// 			min-height: 45px;
// 		}
// 	}
// 	.forgot-password {
// 		display: flex;
// 		justify-content: end;
// 	}
// `;

// export const LogoWrapper = styled.div`
// 	// text-align  : center;
// 	// width       : 100%;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	margin-bottom: 32px;
// 	.img {
// 		max-height: 50px;
// 	}
// `;

export const InputWrapper = styled(Input)`
	input#username {
		background-color: transparent;
	}

	border-radius: 10px;
	padding: 10px 20px;
	background-color: transparent;
	width: 100%;
	border: 2px solid grey;
	height: 50px;

	input::placeholder {
		color: grey;
		font-family: ${FONTS.SECONDARY_LIGHT};
		font-weight: normal;
		font-size: 13px;
	}
	:focus {
		border: 2px solid grey;
		box-shadow: none;
	}
	:hover {
		border: 2px solid grey;
		box-shadow: none;
	}
	:active {
		border: 2px solid grey;
		box-shadow: none;
	}
	input {
		background-color: transparent;
		border: 2px solid grey;
		padding: 10px;
		height: auto;
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: black !important;
		background-color: transparent;
	}
`;
export const PasswordWrapper = styled(Input.Password)`
	border-radius: 10px;
	padding: 10px 20px;
	background-color: transparent;
	width: 100%;
	border: 2px solid grey;
	height: 50px;

	input::placeholder {
		color: grey;
		font-family: ${FONTS.SECONDARY_LIGHT};
		font-weight: normal;
		font-size: 13px;
	}
	:focus {
		border: 2px solid grey;
		box-shadow: none;
	}
	:hover {
		border: 2px solid grey;
		box-shadow: none;
	}
	:active {
		border: 2px solid grey;
		box-shadow: none;
	}
	input {
		background-color: transparent;
		border: 2px solid grey;
		padding: 10px;
		height: auto;
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: black !important;
	}
`;
