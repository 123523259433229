import styled from "styled-components";

import BackgroundImage from "Assets/Images/LoginPageLayer.png";
import { FONTS } from "Styles/Constants";

export const ResetPasswordWrapper = styled.div`
	height: 100vh;
	width: 100%;
	background-image: url(${BackgroundImage});
	background-size: cover;
	background-repeat: no-repeat;
`;

export const ResetPasswordBox = styled.div`
	/* background      : rgba(0,0 ,0, .5); */
	background: linear-gradient(to bottom, #fff600, #fffcb3, #fffede, #f7f7eb, #fff);
	border-radius: 10px;
	box-shadow: 0 0 20.9px 3.1px rgba(4, 23, 39, 0.5);
	padding: 30px 30px 5px;
	width: 500px;

	.reset-text {
		font-family: ${FONTS.SECONDARY_SEMI_BOLD};
		font-size: 26px;
		font-weight: 600;
		text-align: center;
		color: #010101;
	}

	.login-button-parent {
		.button {
			background-color: black;
			color: white;
			width: 100%;
			height: 55px;
			padding: 1rem;
			border-radius: 10px;
			border-color: #000;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 30px;

			:hover {
				box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.24);
			}
			.login-font {
				font-family: ${FONTS.SECONDARY_SEMI_BOLD};
				font-size: 18px;
				font-weight: 600;
				text-align: center;
				margin: 0px;
				padding: 0px;
			}
			&.submit {
				width: 35% !important;
				height: 45px;
			}
		}

		.loader {
			height: 20px;
			width: 20px;
			color: white;
		}
	}

	.login-form-container {
		margin: 20px auto 30px;
		width: 90%;

		.text {
			display: flex;
			font-family: ${FONTS.SECONDARY_SEMI_BOLD};
			font-size: 16px;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #010101;
		}

		.ant-input {
			background: transparent;
			color: #000;
			border: 0;
			padding: 10px 15px;
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			transition: background-color 5000s ease-in-out 0s;
			-webkit-text-fill-color: black !important;
		}

		.ant-input-password {
			background: transparent;
			color: #000;
			/* border: 0; */
			border: 2px solid grey;
			border-radius: 10px;
			padding: 10px 15px;
		}

		input::placeholder {
			color: grey;
			font-family: ${FONTS.SECONDARY_LIGHT};
			font-weight: normal;
			font-size: 13px;
		}
		.ant-input-password {
			.ant-input {
				background: transparent;
				padding: 0;
			}
			.ant-input-suffix {
				svg {
					color: #000;
				}
			}
		}
	}

	.button-container {
		text-align: center;

		.btn {
			text-transform: uppercase;
			min-width: 125px;
			min-height: 45px;
		}
	}
	.forgot-password {
		display: flex;
		justify-content: end;
	}
`;

export const LogoWrapper = styled.div`
	text-align: center;
	width: 100%;

	.img {
		max-height: 75px;
	}
`;
