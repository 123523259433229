export const ACTION_TYPES = {
	LOGIN_USER: "LOGIN_USER",
	LOGOUT_USER: "LOGOUT_USER",
	ROLE: "USER_ROLE",
	SCHOOL_ID: "SCHOOL_ID",
	TEACHER_ID: "TEACHER_ID",
	USER_INFO: "USER_INFO",
	CLASSLINK_USER: "CLASSLINK_USER",
};

export const loginUser = (data) => {
	// SET YOUR LOGIN INFO HERE
	localStorage.setItem("isLoggedIn", true);
	return {
		type: ACTION_TYPES.LOGIN_USER,
		...data,
	};
};

export const logOutUser = () => {
	localStorage.clear();
	return {
		type: ACTION_TYPES.LOGOUT_USER,
	};
};

export const userRole = (role) => {
	return {
		type: ACTION_TYPES.ROLE,
		role,
	};
};

export const reduxSchoolId = (school_id) => {
	return {
		type: ACTION_TYPES.SCHOOL_ID,
		school_id,
	};
};

export const reduxTeacherId = (teacher_id) => {
	return {
		type: ACTION_TYPES.TEACHER_ID,
		teacher_id,
	};
};

export const userInfo = (data) => {
	localStorage.setItem("userInfo", JSON.stringify(data));
	return {
		type: ACTION_TYPES.USER_INFO,
		userInfo: data,
	};
};

export const classLinkUser = (value) => {
	return {
		type: ACTION_TYPES.CLASSLINK_USER,
		isFromClassLink: value,
	};
};
