import { ACTION_TYPES } from "./Actions";

const initState = {
	toast: {
		enable: false,
		message: "",
	},

	//for school/view tests
	testYearId: "",
	testClassId: "",
	testSubjectId: "",
	testTeacherId: "",

	//for school/student/view tests
	studentTestYearId: "",
	studentTestClassId: "",
	studentTestSubjectId: "",
	studentTestTeacherId: "",
	subjectList: localStorage.getItem("subjectList") ? JSON.parse(localStorage.getItem("subjectList")) : [],
};

const Reducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.TOAST_MESSAGE_ENABLE:
			return {
				...state,
				toast: {
					enable: true,
					message: action.message,
				},
			};

		case ACTION_TYPES.TOAST_MESSAGE_DISABLE:
			return {
				...state,
				toast: {
					enable: false,
					message: "",
				},
			};

		case ACTION_TYPES.SERVER_ERROR_ENABLE:
			return {
				...state,
				toast: {
					enable: false,
					message: action.message,
				},
			};

		//for school/view tests
		case ACTION_TYPES.TEST_YEAR_ID:
			return {
				...state,
				testYearId: action.testYearId,
			};

		case ACTION_TYPES.TEST_CLASS_ID:
			return {
				...state,
				testClassId: action.testClassId,
			};

		case ACTION_TYPES.TEST_SUBJECT_ID:
			return {
				...state,
				testSubjectId: action.testSubjectId,
			};

		case ACTION_TYPES.TEST_TEACHER_ID:
			return {
				...state,
				testTeacherId: action.testTeacherId,
			};

		//for school/student/view tests
		case ACTION_TYPES.STUDENT_TEST_YEAR_ID:
			return {
				...state,
				studentTestYearId: action.studentTestYearId,
			};

		case ACTION_TYPES.STUDENT_TEST_CLASS_ID:
			return {
				...state,
				studentTestClassId: action.studentTestClassId,
			};

		case ACTION_TYPES.STUDENT_TEST_SUBJECT_ID:
			return {
				...state,
				studentTestSubjectId: action.studentTestSubjectId,
			};

		case ACTION_TYPES.STUDENT_TEST_TEACHER_ID:
			return {
				...state,
				studentTestTeacherId: action.studentTestTeacherId,
			};

		case ACTION_TYPES.CLEAR_TEST_STATES:
			return {
				...state,
				testYearId: "",
				testClassId: "",
				testSubjectId: "",
				testTeacherId: "",
			};

		case ACTION_TYPES.SELECTED_SUBJECTS_FOR_UPGRADE_SUBSCRIPTION:
			return {
				...state,
				subjectList: action.subjectList,
			};

		default:
			return state;
	}
};

export default Reducer;
