import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { LogoutOutlined } from "@ant-design/icons";

// STYLES
import { HeaderWrapper } from "./Header.style";

// REDUX
import { logOutUser } from "Redux/Auth/Actions";
import { API_URL } from "../../../Helpers";
import Api from "../../../Helpers/ApiHandler";
import { Popconfirm } from "antd";

const logoutText = "Are you sure you want to logout ?";

function Topbar() {
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.Auth.userInfo);

	const logout = async () => {
		const formData = new FormData();
		formData.append("token", localStorage.getItem("accessToken"));

		let response = await new Api().post(API_URL.LOG_OUT, { data: formData });
		if (response.status === 200 || response.status === 201) {
			dispatch(logOutUser());
		}
	};

	return (
		<HeaderWrapper>
			<div className="placeholder"></div>
			<div className="actions">
				{userInfo?.roleName && (userInfo.roleName === "teacher" || userInfo.roleName === "school") ? (
					<>
						{userInfo.roleName === "teacher" && userInfo && userInfo?.firstName && userInfo?.lastName && userInfo?.profilePicUrl && (
							<>
								<img alt="avater" src={userInfo.profilePicUrl} className="avatar" />
								<div className="text">{`Welcome, ${userInfo.firstName} ${userInfo.lastName}`}</div>
							</>
						)}
						{userInfo.roleName === "school" && (
							<>
								<img alt="avater" src={userInfo.profilePicUrl} className="avatar" />
								<div className="text">{`Welcome, ${userInfo?.schoolName}`}</div>
							</>
						)}
					</>
				) : (
					""
				)}
				<Popconfirm placement="bottomRight" title={logoutText} onConfirm={logout} okText="Yes" cancelText="No">
					<LogoutOutlined className="icon" />
				</Popconfirm>
			</div>
		</HeaderWrapper>
	);
}

export default Topbar;
