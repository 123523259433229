import { ACTION_TYPES } from "./Actions";

const initState = {
	isLoggedIn: localStorage.getItem("isLoggedIn") ? localStorage.getItem("isLoggedIn") === "true" : false,
	role: "",
	school_id: "",
	teacher_id: "",
	userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {},
	isFromClassLink: false,
};

const Reducer = (state = initState, action) => {
	switch (action.type) {
		case ACTION_TYPES.LOGIN_USER:
			return {
				...state,
				isLoggedIn: true,
			};

		case ACTION_TYPES.LOGOUT_USER:
			return {
				...state,
				isLoggedIn: false,
				role: "",
				school_id: "",
				teacher_id: "",
			};

		case ACTION_TYPES.ROLE:
			return {
				...state,
				role: action.role,
			};
		case ACTION_TYPES.SCHOOL_ID:
			return {
				...state,
				school_id: action.school_id,
			};
		case ACTION_TYPES.TEACHER_ID:
			return {
				...state,
				teacher_id: action.teacher_id,
			};
		case ACTION_TYPES.USER_INFO:
			return {
				...state,
				userInfo: action.userInfo,
			};
		case ACTION_TYPES.CLASSLINK_USER:
			return {
				...state,
				isFromClassLink: action.isFromClassLink,
			};
		default:
			return state;
	}
};

export default Reducer;
