import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, notification } from "antd";

// STYLES
import { ParentWrapper, InputWrapper, PasswordWrapper } from "./Login.style";
// CONST
import { URL_DASHBOARD } from "Helpers/Paths";
import { loginUser } from "Redux/Auth/Actions";

// IMAGES
import Api from "Helpers/ApiHandler";
import { API_URL } from "Helpers";

//MODAL
import SubscriptionModal from "Components/Pages/Login/SubscriptionModal/SubscriptionModal";

function Login(props) {
	const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
	const getAccessToken = new URLSearchParams(props?.location?.search);
	const AccessToken = getAccessToken.get("token");

	const dispatch = useDispatch();

	useEffect(() => {
		checkIfAlreadyLoggedIn();
		// eslint-disable-next-line
	}, []);

	const [loading, setLoading] = useState(false);

	const [isForgotPassClick, setIsForgotPassClick] = useState(false);

	const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

	const [subscriptionDetails, setSubscriptionDetails] = useState({
		schoolId: "",
		message: "",
		isAlreadyRegistered: false,
	});

	function checkIfAlreadyLoggedIn() {
		if (isLoggedIn) {
			props.history.push(URL_DASHBOARD);
		}
		if (AccessToken) {
			setLoading(true);
			localStorage.setItem("accessToken", AccessToken);
			dispatch(loginUser());
			props.history.push(URL_DASHBOARD);
		}
	}

	async function login(values) {
		try {
			setLoading(true);

			const response = await new Api().post(API_URL.CMS_LOGIN, {
				data: {
					email: values.username,
					password: values.password,
				},
			});

			if (response.status === 200 || response.status === 201) {
				localStorage.setItem("accessToken", response.data);
				dispatch(loginUser());
				props.history.push(URL_DASHBOARD);
			} else if (response.status === 423) {
				//SUBSCRIPTION NOT TAKEN
				const data = response?.data;
				setSubscriptionDetails({
					schoolId: data?.data,
					message: data?.message,
					isAlreadyRegistered: data?.isAlreadyRegistered,
				});
				setIsSubscriptionModalOpen(true);
			}
			setLoading(false);
		} catch (err) {
			console.log("err:", err);
			setLoading(false);
		}
	}

	const forgotPassClick = () => {
		setIsForgotPassClick(true);
	};

	function cancelClick() {
		setIsForgotPassClick(false);
	}

	async function clickForgotPassword(values) {
		const formData = new FormData();
		formData.append("email", values.forgotPassword);
		let response = await new Api().post(API_URL.ADMIN_FORGOT_PASSWORD, { data: formData });
		if (response.status === 200 || response.status === 201) {
			setIsForgotPassClick(false);
			notification.success({ message: "Success", description: response?.message, duration: 3 });
		} else {
			setIsForgotPassClick(false);
		}
	}

	const handleRedirectToSubscription = () => {
		window.location.href = `${process.env.REACT_APP_WEBSITE_URL}subscription?id=${subscriptionDetails?.schoolId}&isAlreadyRegistered=${subscriptionDetails?.isAlreadyRegistered}`;
	};

	const handleCancel = () => {
		setIsSubscriptionModalOpen(false);
	};

	return (
		<ParentWrapper isForgotPassword={isForgotPassClick}>
			<div className="main-container">
				<div className="modal">
					{!isForgotPassClick && (
						<Form onFinish={login}>
							<div className="content">
								<div className="header">
									<p className="header">Admin Portal Login</p>
								</div>
								<div>
									<div className="flex-column">
										<p className="text">Email address</p>
										<Form.Item name="username" rules={[{ required: true, message: "Please input email." }]}>
											<InputWrapper size="large" placeholder="Email" />
										</Form.Item>

										<p className="text">Password</p>
										<Form.Item name="password" rules={[{ required: true, message: "Please input password." }]}>
											<PasswordWrapper size="large" placeholder="Password" flag="password" />
										</Form.Item>
										<div className={"forgot-password-parent"}>
											<span className={"forgot-password"} onClick={() => forgotPassClick()}>
												Forgot Password?
											</span>
										</div>
									</div>
								</div>

								<div className="login-button-parent">
									<Button className="button" htmlType="submit" loading={loading}>
										<span className="login-font">LOGIN</span>
									</Button>
								</div>
							</div>
						</Form>
					)}
					{isForgotPassClick && (
						<Form className="login-form-container" onFinish={clickForgotPassword}>
							<div className="content">
								<div className="header">
									<p className="header">Forgot Password</p>
								</div>
								<Form.Item name="forgotPassword" rules={[{ required: true, message: "Please input your email." }]}>
									<InputWrapper size="large" placeholder="Email" />
								</Form.Item>
								<div className="button-container login-button-parent" style={{ display: "flex", justifyContent: "space-around" }}>
									<Button className="button cancel" htmlType="reset" onClick={cancelClick}>
										Cancel
									</Button>
									<Button className="button submit" htmlType="submit" loading={loading}>
										Submit
									</Button>
								</div>
							</div>
						</Form>
					)}
					{isSubscriptionModalOpen && (
						<SubscriptionModal
							setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
							isSubscriptionModalOpen={isSubscriptionModalOpen}
							handleRedirectToSubscription={handleRedirectToSubscription}
							handleCancel={handleCancel}
							subscriptionDetails={subscriptionDetails}
						/>
					)}
				</div>
			</div>
		</ParentWrapper>
	);
}

export default Login;
