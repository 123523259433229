import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import EmailNotificationModal from "./Modal/EmailNotificationModal";
import { notification } from "antd";

// STYLES
import { SidebarWrapper, MenuStyled, MenuItem, SubMenu, LogoWrapper } from "./Sidebar.style";

// IMAGES
// import LogoImage from "Assets/Images/logo.png";
import SubjectMasterLogo from "Assets/Images/subjectmaster.png";

// CONST
import Routes from "Routes/RoutesList";
import { DIMENSIONS } from "Styles/Constants";
import Api from "../../../Helpers/ApiHandler";
import { API_URL, URL_CLASS, URL_SCHOOL_TEACHERS_STUDENT, URL_TEACHER, URL_TEST_ASSIGN_TO_CLASS, URL_CHANGE_PASSWORD } from "../../../Helpers";
import { useDispatch } from "react-redux";
import { userRole, reduxSchoolId, reduxTeacherId, userInfo, classLinkUser } from "Redux/Auth/Actions";

function Sidebar(props) {
	const [routes, setRoutes] = useState([]);

	const [userAccess, setUserAccess] = useState("");
	const [emailVerification, setEmailVerification] = useState(false);
	const [loading, setLoading] = useState(false);
	const [classLink, setClassLink] = useState(false);

	const [schoolId, setSchoolId] = useState("");
	const dispatch = useDispatch();

	const getUserStatus = useCallback(async () => {
		const responseStatus = new Api().get(API_URL.GET_USER_STATUS);
		const responseInfo = new Api().get(API_URL.VIEW_ADMIN_USER_PROFILE);

		const [responseStatusUser, responseInfoUser] = await Promise.all([responseStatus, responseInfo]);

		if ((responseStatusUser.status === 200 || responseStatusUser.status === 201) && (responseInfoUser.status === 200 || responseInfoUser.status === 201)) {
			dispatch(userRole(responseStatusUser.data.role));
			if (responseStatusUser?.data?.schoolId) {
				localStorage.setItem("schoolId", responseStatusUser?.data?.schoolId);
				dispatch(reduxSchoolId(responseStatusUser.data.schoolId));
				dispatch(classLinkUser(responseStatusUser?.data?.isFromClassLink));
			}
			if (responseStatusUser?.data?.role === "teacher") {
				setClassLink(responseStatusUser?.data?.isFromClassLink);
				localStorage.setItem("teacherId", responseStatusUser?.data?.id);
				dispatch(reduxTeacherId(responseStatusUser?.data?.id));
			}
			if (responseInfoUser?.data) {
				dispatch(userInfo(responseInfoUser.data));
			}
			if (responseInfoUser?.data?.roleName === "school") {
				setEmailVerification(responseInfoUser?.data?.isShowEmailNotification);
			}
			setUserAccess(responseStatusUser.data.role);
			setSchoolId(responseStatusUser.data.schoolId);

			/*
			 *  In RoutList object "access" key usage
			 * -> That key "access" is used for showing module in sidebar.
			 * */

			let filtered = Routes.filter((route) => {
				if (route.access.includes(responseStatusUser.data.role)) {
					return route.sidebar;
				}
			});

			setRoutes(filtered);
		}
	}, [dispatch]);

	const sendEmailNotification = async () => {
		setLoading(true);
		const response = await new Api().post("/send/school/verification-email");
		if (response.status === 200 || response.status === 201) {
			setEmailVerification(false);
			setLoading(false);
			notification.info({ message: "Info", description: "Email verification Link has been sent to your email address.", duration: 3 });
		}
	};

	useEffect(() => {
		getUserStatus();
	}, [getUserStatus]);

	function listItemClick(route) {
		if (userAccess === "school") {
			if (route.path === URL_TEACHER + ":schoolId") {
				props.history.push(URL_TEACHER + schoolId);
			} else if (route.path === URL_CLASS + ":schoolId") {
				props.history.push(URL_CLASS + schoolId);
			} else if (route.path === URL_SCHOOL_TEACHERS_STUDENT + ":schoolId") {
				props.history.push(URL_SCHOOL_TEACHERS_STUDENT + schoolId);
			} else if (route.path === URL_TEST_ASSIGN_TO_CLASS + ":schoolId") {
				props.history.push(URL_TEST_ASSIGN_TO_CLASS + schoolId);
			} else {
				props.history.push(route.path);
			}
		} else if (userAccess === "teacher") {
			if (route.path === URL_SCHOOL_TEACHERS_STUDENT + ":schoolId") {
				props.history.push(URL_SCHOOL_TEACHERS_STUDENT + schoolId);
			} else if (route.path === URL_CLASS + ":schoolId") {
				props.history.push(URL_CLASS + schoolId);
			} else if (route.path === URL_TEST_ASSIGN_TO_CLASS + ":schoolId") {
				props.history.push(URL_TEST_ASSIGN_TO_CLASS + schoolId);
			} else {
				props.history.push(route.path);
			}
		} else {
			props.history.push(route.path);
		}
	}

	return (
		<>
			<SidebarWrapper width={DIMENSIONS.SIDEBAR.FULL}>
				<LogoWrapper className="flex f-v-center f-h-start">
					<img className="img" src={SubjectMasterLogo} alt="Logo" />
				</LogoWrapper>
				<MenuStyled mode="inline" defaultSelectedKeys={[props.history.location.pathname]}>
					{routes.map((route) => {
						return route.subnest ? (
							<SubMenu key={route.path} icon={route.sidebar.icon} title={route.sidebar.name}>
								{route.sidebar.nested.map((child) =>
									child.single ? (
										<MenuItem
											key={child.path}
											icon={child.icon}
											onClick={() => {
												listItemClick(child);
											}}
										>
											{child.name}
										</MenuItem>
									) : (
										<SubMenu key={child.path} icon={child.icon} title={child.name}>
											{child.sub_nested.map((item) => (
												<MenuItem
													key={item.path}
													icon={item.icon}
													onClick={() => {
														listItemClick(item);
													}}
												>
													{item.name}
												</MenuItem>
											))}
										</SubMenu>
									)
								)}
							</SubMenu>
						) : route.simple ? (
							<SubMenu key={route.path} icon={route.sidebar.icon} title={route.sidebar.name}>
								{route.sidebar.nested.map((child) => {
									return classLink && child.path === URL_CHANGE_PASSWORD ? null : (
										<MenuItem
											key={child.path}
											icon={child.icon}
											onClick={() => {
												listItemClick(child);
											}}
										>
											{child.name}
										</MenuItem>
									);
								})}
							</SubMenu>
						) : (
							<MenuItem
								key={route.path}
								icon={route.sidebar.icon}
								onClick={() => {
									listItemClick(route);
								}}
							>
								{route.sidebar.name}
							</MenuItem>
						);
					})}
				</MenuStyled>
			</SidebarWrapper>
			{emailVerification && <EmailNotificationModal visible={emailVerification} onClose={() => setEmailVerification(false)} sendEmailNotification={sendEmailNotification} loading={loading} />}
		</>
	);
}

export default withRouter(Sidebar);
