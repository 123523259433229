import React from "react";
import { Modal } from "antd";

const EmailNotificationModal = (props) => {
	return (
		<Modal
			visible={props.visible}
			title="Email Verification"
			onCancel={() => props.onClose()}
			onOk={props?.sendEmailNotification}
			okText="Verify"
			okButtonProps={{ loading: props?.loading }}
			maskClosable={false}
		>
			<h3>Please verify your Email. This email address will be used for all subscription-related correspondence.</h3>
		</Modal>
	);
};

export default EmailNotificationModal;
