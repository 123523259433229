import { API_URL } from "./Paths";

export const MODAL_TYPES = {
	ADD: "ADD",
	EDIT: "EDIT",
	CLOSE: "CLOSE",
	VIEW: "VIEW",
};
export const FILTER_DIRECTION = {
	Ascending: "ascend",
	Descending: "descend",
};

export const TOTAL_RECORDS_PER_PAGE = {
	TOTAL_RECORDS_PER_PAGE: 10,
};

export const MEDIA_TYPE = {
	TEXT: "TEXT",
	IMAGE: "IMAGE",
	VIDEO: "VIDEO",
};

export const All_ACCESS = ["admin", "teacher", "school"];
export const ADMIN_ACCESS = ["admin"];
export const SCHOOL_ACCESS = ["school"];
export const SCHOOL_TEACHER_ACCESS = ["school", "teacher"];
export const ADMIN_SCHOOL_ACCESS = ["admin", "school"];

export const PROGRESS_BAR_TYPE = {
	LINE: "line",
	CIRCLE: "circle",
	DASHBOARD: "dashboard",
};

export const GAME_MODULE_NAME = {
	LEGISLATIVE_BILLNOBILL: "LEGISLATIVE_BILLNOBILL",
	HISTORY_BILLNOBILL: "HISTORY_BILLNOBILL",
	EXECUTIVE: "EXECUTIVE",
	JUDICIAL_SPIN: "JUDICIAL_SPIN",
	JUDICIAL_SUPREME: "JUDICIAL_SUPREME",
	CONSTITUTION: "CONSTITUTION",
	QUIZ: "QUIZ",
	FINAL: "FINAL",
	RAPIDFIRE_FOREIGN: "RAPIDFIREFOREIGNLANGUAGE",
	QUIZ_FOREIGN_LANGUAGE: "QUIZ_FOREIGN_LANGUAGE",
	RAPIDFIRE_HISTORY: "RAPIDFIREHISTORY",
	BILLNOBILL_HISTORY: "BILLNOBILL_HISTORY",
	RAPIDFIRE_SCIENCE: "RAPIDFIRESCIENCE",
	PERIODIC_TABLE_SCIENCE: "PERIODICTABLESCIENCE",
	LOOSE_LINK: "LOOSE_LINK",
	RAPIDFIRE_PURSUIT: "RAPIDFIREPURSUIT",
	RAPID_FIRE_ECONOMICS: "RAPIDFIREECONOMICS",
	PURSUIT_RAPID_FIRE: "PURSUIT_RAPID_FIRE",
	PURSUIT_CHASER: "PURSUIT_CHASER",
	PURSUIT: "PURSUIT",
	CROSSWORD: "CROSSWORD",
	CONNECT4: "CONNECT4",
	MATHAHTZEE: "MATHAHTZEE",
	RAPID_FIRE: "RAPID_FIRE",
	PERIODIC_TABLE: "PERIODIC_TABLE",
	RAPID_FIRE_CHASER: "RAPIDFIRECHASER",
	RAPID_FIRE_ENGLISH: "RAPIDFIREENGLISH",
};

export const UPLOAD_TYPES = {
	IMG: ["image/png", "image/jpg", "image/jpeg"],
	VIDEO: ["video/mp4", "video/mkv"],
};

export const QUESTION_TYPE = {
	TEXT: "Text Type",
	SINGLE_CHOICE: "Single Choice",
	MULTIPLE_CHOICE: "Multiple Choice",
	RANK: "Rank Type",
};

export const API_MAPPING = {
	[GAME_MODULE_NAME.CONSTITUTION]: API_URL.SAVE_CHATGPT_CONSTITUTION_QUESTION,
	[GAME_MODULE_NAME.JUDICIAL_SPIN]: API_URL.SAVE_CHATGPT_JUDICIAL_SPIN_QUESTION,
	[GAME_MODULE_NAME.JUDICIAL_SUPREME]: API_URL.SAVE_CHATGPT_JUDICIAL_SUPREME_QUESTION,
	[GAME_MODULE_NAME.LEGISLATIVE_BILLNOBILL]: API_URL.SAVE_CHATGPT_LEGISLATIVE_BILL_NO_BILL_QUESTION,
	[GAME_MODULE_NAME.EXECUTIVE]: API_URL.SAVE_CHATGPT_EXECUTIVE_ESCAPE_ROOM_QUESTION,
	[GAME_MODULE_NAME.RAPID_FIRE]: API_URL.SAVE_CHATGPT_RAPID_FIRE_QUESTION,
	[GAME_MODULE_NAME.HISTORY_BILLNOBILL]: API_URL.SAVE_CHATGPT_HISTORY_BILL_NO_BILL_QUESTION,
	[GAME_MODULE_NAME.QUIZ]: API_URL.SAVE_CHATGPT_QUIZ_QUESTION,
	[GAME_MODULE_NAME.FINAL]: API_URL.SAVE_CHATGPT_FINAL_QUESTION,
	[GAME_MODULE_NAME.PURSUIT]: API_URL.SAVE_CHATGPT_PURSUIT_QUESTION,
	[GAME_MODULE_NAME.PURSUIT_CHASER]: API_URL.SAVE_CHATGPT_PURSUIT_CHASER_QUESTION,
	[GAME_MODULE_NAME.PERIODIC_TABLE]: API_URL.SAVE_CHATGPT_PERIODIC_TABLE_QUESTION,
	[GAME_MODULE_NAME.LOOSE_LINK]: API_URL.SAVE_CHATGPT_LOOSE_LINK_QUESTION,
	[GAME_MODULE_NAME.CONNECT4]: API_URL.SAVE_CHATGPT_CONNECT4_QUESTION,
	[GAME_MODULE_NAME.MATHAHTZEE]: API_URL.SAVE_CHATGPT_MATHAHTZEE_QUESTION,
	[GAME_MODULE_NAME.CROSSWORD]: API_URL.ADD_CROSSWORD_PUZZLE,
};
