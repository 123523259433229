import { createGlobalStyle } from "styled-components";
import { FONTS, responsive, COLORS } from "./Constants";
import RubikBlack from "Assets/Fonts/Rubik-Black.ttf";
import RubikBold from "Assets/Fonts/Rubik-Bold.ttf";
import RubikMedium from "Assets/Fonts/Rubik-Medium.ttf";
import Rubik from "Assets/Fonts/Rubik.ttf";
import RubikLight from "Assets/Fonts/Rubik-Light.ttf";

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }
    html,
    body{
        margin: 0;
        padding: 0;
    }
    body{
        background-color    : #f0f2f5;
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }
    #root {
        display: flex;
    }
    .full-height{
        height: 100%;
    }
    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-v-center{
        align-items: center;
    }
    .f-h-center{
        justify-content: center;
    }
    .f-h-start{
        justify-content: flex-start;
    }
    
    .f-h-space-between{
        justify-content: space-between;
    }

    .f-column{
        flex-direction: column;
    }

    .text-center{
        text-align: center;
    }

    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }
    .hidden-md{
        ${responsive.TABLET`display:none`}
    }
    .plain-notification{
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }

    .ant-table-body { 
        overflow-x: auto !important
    } 
    // .ant-btn {
    //     min-width       : 100px;
    // }
 
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color    : rgba(162, 162, 162, 0.5); 
        border-radius       : 4px;
    }

    .form-scroll{
        max-height: 60vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 10px 0 0; 
    }

    .margin-top{
        margin:15px 0;
    }

    .select{
        width: 200px;
    }

    .button {
        width: 90px;
    }

    .header-details {
		gap: 10px;
        margin-bottom: 20px;
		.text {
			font-size: 14px;
			font-weight: 400;
			color: ${COLORS.GREY_TEXT_LIGHT};
		}
	}

    @font-face {
        font-family : ${FONTS.SECONDARY_SEMI_BOLD};
        src         : url(${RubikMedium});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_BOLD};
        src         : url(${RubikBold});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_BLACK};
        src         : url(${RubikBlack});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY};
        src         : url(${Rubik});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_LIGHT};
        src         : url(${RubikLight});
    }
`;
