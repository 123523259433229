import React, { useState } from "react";
import { withRouter } from "react-router";

import { Button, Input, Form, notification } from "antd";

// STYLES
import { ResetPasswordWrapper, ResetPasswordBox } from "./ResetPassword.style";

// IMAGES
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { API_URL } from "Helpers";
import Api from "Helpers/ApiHandler";

function ResetPassword(props) {
	const [loading, setLoading] = useState(false);

	async function resetPassword(values) {
		if (values.password1 !== values.password2) {
			notification.error({ message: "Error", description: "Please Enter correct password. Both Passwords are different.", duration: 3 });
			return;
		}
		setLoading(true);
		const formData = new FormData();
		formData.append("token", props?.match?.params?.token);
		formData.append("newPassword", values?.password2);

		const response = await new Api().post(API_URL.ADMIN_RESET_PASSWORD, {
			data: formData,
		});
		if (response.status === 200 || response.status === 201) {
			notification.success({ message: "Success", description: "Password changed successfully", duration: 3 });
		}
		setLoading(false);
	}

	return (
		<ResetPasswordWrapper className="flex f-v-center f-h-center">
			<ResetPasswordBox className="box">
				{/* <LogoWrapper>
                    <img className="img" src={LogoImage} alt="Logo"/>
                </LogoWrapper> */}
				<div className="reset-text">Reset Password</div>
				<Form className="login-form-container" onFinish={resetPassword} layout="vertical">
					<p className="text">New Password</p>
					<Form.Item name="password1" rules={[{ required: true, message: "Please enter your new password." }]}>
						<Input.Password placeholder="New Password" size="large" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
					</Form.Item>
					<p className="text">Confirm Password</p>
					<Form.Item name="password2" rules={[{ required: true, message: "Please Re enter your new password." }]}>
						<Input.Password placeholder="Re Enter New Password" size="large" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
					</Form.Item>
					<div className="login-button-parent">
						<Button className="button" htmlType="submit" loading={loading}>
							<span className="login-font"> Reset Password</span>
						</Button>
					</div>
				</Form>
			</ResetPasswordBox>
		</ResetPasswordWrapper>
	);
}

export default withRouter(ResetPassword);
