import React from "react";
import { Modal } from "antd";

function SubscriptionModal(props) {
	return (
		<Modal visible={props?.isSubscriptionModalOpen} title="Subscription" onOk={props?.handleRedirectToSubscription} onCancel={props?.handleCancel} okText="Purchase">
			<h3>{props?.subscriptionDetails?.message} </h3>
		</Modal>
	);
}

export default SubscriptionModal;
